import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import Choice from './Choice'
import iconemail from '../assets/images/icon-email.png';

const ContactUs = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [isChecked, setIsChecked] = useState (false)
    const [submit, setSubmit] = useState (false);

    const onSubmit = (data) => {
        console.log(data);
        document.getElementById ('thank-you-box').style.display = 'block';
        reset(); // <-- reset the form after submitting
    }

    const close = () => {
        setSubmit (false);
        document.getElementById ('thank-you-box').style.display = 'none';
        reset(); // <-- reset the form after submitting
    };

    
    function handleCheckboxChange (event) {
    setIsChecked (event.target.checked);
    }

    return ( 
        <div className='terms-container contact-container'>
            <div className='contact-header'>
                <Container>
                    <h1>Contact Us</h1>
                </Container>
            </div>
            <Container className='contact-form-section'>
                <div>
                    <h4 className='help-you'>Were here to help you</h4>
                    <p className='question-help'>Have a question, comment or brilliant idea you’d like to share. Send us a note here, We would love to hearfrom you and always reply.</p>
                </div>
                <div>
                    <p className='do-you-want'>Do you want to get in touch?</p>
                    <p className='question-help'>Let Us know how we can help you</p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='contact-form'>
                            <Col>
                                <input 
                                    type='text' 
                                    placeholder='First Name' 
                                    {...register("firstName", { required: true, maxLength: 25 })}
                                />
                                {errors.firstName && <p className="text-error">Please check the First Name</p>}
                                <input 
                                    type='email' 
                                    placeholder='Email Address' 
                                    {...register("email", 
                                    { 
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }
                                    )}
                                />
                                {errors.email && <p className="text-error">Please check the Email</p>}
                            </Col>
                            <Col>
                                <input 
                                    type='text' 
                                    placeholder='Last Name' 
                                    {...register("lastName", { required: true, maxLength: 25 })}
                                />
                                {errors.lastName && <p className="text-error">Please check the Last Name</p>}
                                <input 
                                    type='text' 
                                    placeholder='Phone'
                                    {...register("phone",
                                        { 
                                           required: true,
                                           pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                                        }
                                    )}
                                />
                                {errors.phone && <p className="text-error">Please check the Phone</p>}
                            </Col>
                        </Row>
                        <textarea 
                            placeholder='Enter Message' 
                            style={{width:'100%', height:'100px', border:'1px solid #D9D9D9', borderRadius:'4px', marginTop:'20px'}}
                            {...register("message",
                                { 
                                    required: true
                                }
                            )}
                        ></textarea>
                        {errors.message && <p className="text-error">Message is empty</p>}
                        
                        <Row>
                            <Col>
                                <label className='page-text'>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        style={{marginRight: '1rem', marginBlock: '2rem'}}
                                    />
                                    By Submitting this order, you agree with our
                                    {' '}
                                    <a href="/termsofuse" className="links">terms of  service</a>
                                    {' '}
                                    and
                                    {' '}
                                    <a href="/privacypolicy" className="links">privacy policy</a>
                                </label>
                            </Col>
                        </Row>

                        <button 
                            type='submit' 
                            className='contact-submit'
                            disabled={!isChecked || submit}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Container>
                <Choice />
      <div className="thank-you-box" id="thank-you-box">
        <div className="icon-mail">
          <img src={iconemail} alt="Mail Icon" className="env-icon" />
        </div>
        <h1>THANK YOU!</h1>
        <p>Thanks again for contacting Top Animated Videos today. </p>
        <button className="alright-btn" onClick={close}>Alright!</button>
      </div>
        </div>
     );
}
 
export default ContactUs;