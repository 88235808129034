import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const GetInTouch = () => {
    const navigate = useNavigate()

    const handleGetStated = () => {
        navigate('/requestquotation')
    }

    return ( 
        <div className='process-container get-in-touch-container'>
            <Container className='get-in-touch-container'>
                <h1>Still Have Questions? Get In Touch with our Team</h1>
                <div className='get-in-touch-btn-section'>
                    <span className='get-started why-get-btn get-in-touch-btn' onClick={() => {navigate('/contactus')}}>Get in Touch</span>
                </div>
            </Container>
        </div>
     );
}
 
export default GetInTouch;