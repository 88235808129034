import React from 'react';
import logo from '../assets/images/22.png'

const Nav = () => {
    return ( 
        <div>
            <div className='navigation'>
                <header>
                    <div className='container'>
                        <div className='main-nav'>
                        <a href='/' className='nav-link'><img src={logo} className='logo' alt='Top Animated Videos' /></a>
                            <ul className='nav-list'>
                                <li><a href='/#top' className='nav-link'> Home</a></li>
                                <li><a href='/#packages' className='nav-link'> Packages</a> </li>
                                <li><a href='/#process' className='nav-link'> Process</a></li>
                                <li><a href='/#portfolio' className='nav-link'> Portfolio</a></li>
                                <li><a href='/#about' className='nav-link'> About Us</a></li>
                                <li><a href="https://calendly.com/taav/free?hide_gdpr_banner=1"   target="_blank" className='contact-us'>Free Consultation</a></li>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
        </div>
     );
}
 
export default Nav;