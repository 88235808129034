import React from 'react';
import { Container } from 'react-bootstrap';
import test2 from '../assets/images/test2.png'
import conversationBubble1 from '../assets/images/conversation-bubble1.png'
import recordVoiceOver1 from '../assets/images/record-voice-over1.png'
import animation1 from '../assets/images/animation1.png'
import right1 from '../assets/images/right1.png'
import giveGift1 from '../assets/images/giveGift1.png'

const Process = () => {
    return ( 
        <div className='process-container' id='process'>
            <Container>
                <div className='packages-header process-header'>
                    <h1 className='section-header'>Our Process</h1>
                    <p>We have a simple workflow process, which starts when the client submits the order for delivery of the final video file to the client.</p>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center' }}>
                    <div className='process-section'>
                        <div className='single-process-card'>
                            <img src={test2} alt='test 2' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>Submit the Order</p>
                                <p className='process-text'>The client selects an appropriate package, completes the payment, and submits the order to us. Afterward, we send a confirmation email to the client.</p>
                            </div>
                        </div>
                        <div className='single-process-card'>
                            <img src={conversationBubble1} alt='conversation-bubble1' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>Scripting</p>
                                <p className='process-text'>The client completes the form with the necessary information (requirements) about the project (video) to guide our team. This form is resent to us for immediate action.</p>
                            </div>
                        </div>
                    </div>
                    <div className='process-section'>
                        <div className='single-process-card'>
                            <img src={recordVoiceOver1} alt='recordVoiceOver1' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>Develop the video Script</p>
                                <p className='process-text'>Our team will craft a full script for the video which conveys the client’s message in the best manner. Afterward, this script is reviewed and endorsed by the clients with their valued input.</p>
                            </div>
                        </div>
                        <div className='single-process-card'>
                            <img src={animation1} alt='animation1' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>The video Voice-over</p>
                                <p className='process-text'>We produce a powerful professional voice-over that transits the script “text” into “sound” to perfectly communicate the message to the target audience.</p>
                            </div>
                        </div>
                    </div>
                    <div className='process-section'>
                        <div className='single-process-card'>
                            <img src={right1} alt='process-vector' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>Create Perfect Animations</p>
                                <p className='process-text'>Our animators puts their creative capabilities into action. It involves adding animated characters to match the recorded sound (voice-over) message to produce more engaging video content for the target audience.</p>
                            </div>
                        </div>
                        <div className='single-process-card'>
                            <img src={giveGift1} alt='giveGift1' className='process-vector' />
                            <div className='single-process-cont'>
                                <p className='process-title'>Complete the Project Delivery</p>
                                <p className='process-text'>We share the first version of the project with the client for review. The client can request revisions to perfect the video in their context. And once the video is finalized, the file is shared.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
     );
}
 
export default Process;