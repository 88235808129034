import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import MobileMenu from './components/Hamburger';
import Home from './components/Home';
import Nav from './components/Nav';
import Payment from './components/Payment';
import PrivacyPolicy from './components/PrivacyPolicy';
import RequestQuotation from './components/RequestQuotation';
import TermsOfUse from './components/TermsOfUse';

function App() {
  return (
    <div className="App">
      <Router>
        <MobileMenu />
        <Nav />
        <Routes>
          <Route path='/' element={ <Home /> } exact/>
          <Route path='/termsofuse' element={ <TermsOfUse /> } />
          <Route path='/privacypolicy' element={ <PrivacyPolicy /> } />
          <Route path='/contactus' element={ <ContactUs /> } />
          <Route path='/payment' element={ <Payment /> } />
          <Route path='/requestquotation' element={ <RequestQuotation /> } />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
