import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import iconemail from '../assets/images/icon-email.png';
import Choice from './Choice';

const RequestQuotation = () => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm()
    const [value, setValue] = useState ('');
    const [submit, setSubmit] = useState (false);
    const [isChecked, setIsChecked] = useState (false);

    const email = watch ('email');

    const onChange = e => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test (e.target.value)) {
            setValue (e.target.value);
        }
    }

    const close = () => {
        setSubmit (false);
        document.getElementById ('thank-you-box').style.display = 'none';
        reset(); // <-- reset the form after submitting
    };

    const onSubmit = (data) => {
        console.log(data);
        document.getElementById ('thank-you-box').style.display = 'block';
    }

    
    function handleCheckboxChange (event) {
        setIsChecked (event.target.checked);
    }

    return ( 
        <div className='request-quotation'>
            <Container>
                <div className='static-page-section'>
                    <h1 className='quotation-title'>Request a Quotation</h1>
                    <p className='page-text'>Thank you for considering our animation video services. We are a team of creative professionals dedicated to producing high-quality animated videos that engage and inspire your audience. Please provide us with the following details and we shall send the quotation in just a few minutes.</p>
                </div>

                <form className='quote-form' onSubmit={handleSubmit(onSubmit)}>
                    <Row className='quote-row' style={{marginTop:'60px'}}>
                        <p className='page-text form-par'><span>Service Package</span></p>
                        <Col md={12} ><input type='radio' name='service' id='whiteboard' value='Whiteboard videos'/>&emsp;<label for="whiteboard">Whiteboard videos</label></Col>
                        <Col md={12} ><input type='radio' name='service' id='2d' value='2D animated Videos'/>&emsp;<label for="2d">2D animated Videos</label></Col>
                        <Col md={12} ><input type='radio' name='service' id='premium' value='Premium animated Videos'/>&emsp;<label for="premium">Premium animated Videos</label></Col>
                    </Row>
                    
                    <Row className='quote-row' style={{marginTop:'60px'}}>
                        <p className='page-text form-par'><span>Video Length</span></p>
                        <Col md={12} ><input type='radio' name='videoLength' id='fifteen' value='15 Seconds'/>&emsp;<label for="fifteen">15 Seconds</label></Col>
                        <Col md={12} ><input type='radio' name='videoLength' id='thirty' value='30 Seconds'/>&emsp;<label for="thirty">30 Seconds</label></Col>
                        <Col md={12} ><input type='radio' name='videoLength' id='sixty' value='60 Seconds'/>&emsp;<label for="sixty">60 Seconds</label></Col>
                        <Col md={12} ><input type='radio' name='videoLength' id='ninety' value='90 Seconds'/>&emsp;<label for="ninety">90 Seconds</label></Col>
                        <Col md={12} ><input type='radio' name='videoLength' id='custom' value='custom Seconds'/>&emsp;<label for="custom">Customize <input
                        type="text"
                        style={{width: '5rem'}}
                        value={value}
                        onChange={onChange}
                    /> Seconds</label></Col>
                    </Row>

                    <Row style={{fontSize:'18px', marginTop:'60px'}}>
                    <p className='page-text form-par'><span>Contact Details</span></p>
                        <Col md={6}>
                            <label for="name">Name</label><br/>
                            <input 
                                type='text' 
                                placeholder="Johnson Maxwell" 
                                id="name"
                                className="quote-form-input"
                                {...register("name", 
                                    { 
                                        required: true, 
                                        maxLength: 25 
                                    })
                                }
                            />
                            {errors.name && <p className="text-error">Please check the Name</p>}
                        </Col>
                        <Col md={6}>
                            <label for="email">Email</label><br/>
                            <input 
                                type='text' 
                                placeholder="jmax@company.com" 
                                id="email"
                                className="quote-form-input"
                                {...register("email", 
                                { 
                                    required: true,
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                }
                                )}
                            />
                            {errors.email && <p className="text-error">Please check the Email</p>}
                        </Col>
                    </Row>
                    <Row style={{fontSize:'18px', marginBottom:'20px'}}>
                        <Col md={6}>
                            <label for="country">Country</label> <br/>
                            <input 
                            type='text' 
                            placeholder="Canada" 
                            id="country"
                            className="quote-form-input"
                            {...register("country", 
                                { 
                                    required: true, 
                                    maxLength: 25 
                                })
                            }
                            />
                            {errors.country && <p className="text-error">Please check the Country</p>}
                        </Col>
                        <Col md={6}>
                            <label for="phone">Phone</label><br/>
                            <input 
                                type='text' 
                                placeholder="+16308520397" 
                                id="phone"
                                className="quote-form-input"
                                {...register("phone",
                                    { 
                                    required: true,
                                    pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                                    }
                                )}
                            />
                            {errors.phone && <p className="text-error">Please check the Phone</p>}
                        </Col>
                    </Row>
                    <Row  style={{marginTop:'60px'}}>
                        <p className='page-text form-par'><span>Your Budget</span></p>
                        <Row>
                            <Col md={4} className='quote-row' >
                                <input type='radio' {...register ('budget', {required: true})} name='budget' id='oneThousand' value='USD $1,000 - USD $2,999'/>&emsp;<label for="oneThousand">USD 1,000 - USD 2,999</label><br/>
                                <input type='radio' {...register ('budget', {required: true})} name='service' id='threeThousand' value='USD $3,000 - USD $5,999'/>&emsp;<label for="threeThousand">USD 3,000 - USD 5,999</label>
                            </Col>
                            <Col md={4} className='quote-row' >
                                <input type='radio' {...register ('budget', {required: true})} name='budget' id='sixThousand' value='USD $6,000 - USD $11,999'/>&emsp;<label for="sixThousand">USD 6,000 - USD 11,999</label><br/>
                                <input type='radio' {...register ('budget', {required: true})} name='budget' id='twelve' value='USD $12,000 - USD $23,999'/>&emsp;<label for="twelve">USD 12,000 - USD 23,999</label>
                            </Col>
                            <Col md={4} className='quote-row' >
                                <input type='radio' {...register ('budget', {required: true})} name='budget' id='twentyfour' value='USD $24,000 - USD $47,999'/>&emsp;<label for="twentyfour">USD 24,000 - USD 47,999</label><br/>
                                <input type='radio' {...register ('budget', {required: true})} name='budget' id='custom' value='USD $12,000 - USD $23,999'/>&emsp;<label for="custom">Customize USD________</label>
                            </Col>
                        </Row>
                        {errors.budget &&
                            <p className="text-error">Please check the budget</p>
                        }
                    </Row>

                    <Row style={{fontSize:'18px', marginTop:'60px'}}>
                        <Col>
                        <p className='page-text form-par'><span>Your Idea/Project</span></p>
                        <textarea 
                            style={{width:'100%', height:'200px'}}
                            {...register("message",
                                { 
                                    required: true
                                }
                            )}
                            placeholder="In this space, please describe your business and the main challenges you face, as well as the type of software you require to enhance your performance."
                        ></textarea>
                        {errors.message && <p className="text-error">Message is empty</p>}

                        <p className='page-text' style={{marginTop:'60px', lineHeight:'28px'}}>
                            We look forward to working with you and bringing your vision to life. If you have any further questions, please don't hesitate to contact us (<span>help@topanimatedvideo.com)</span>.
                        </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className='page-text'>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    style={{marginRight: '1rem', marginBlock: '2rem'}}
                                />
                                By Submitting this order, you agree with our
                                {' '}
                                <a href="/termsofuse" className="links">terms of  service</a>
                                {' '}
                                and
                                {' '}
                                <a href="/privacypolicy" className="links">privacy policy</a>
                            </label>
                        </Col>
                    </Row>
                    <Row style={{fontSize:'18px', marginTop:'60px', marginTop:'60px'}}>
                        <Col style={{textAlign:'center'}}>
                            <Button 
                                type='submit'
                                style={{width:'200px', fontSize:'20px', background:'#CE1D74', border:'1px solid #CE1D74', marginTop:'20px'}}
                                disabled={!isChecked || submit}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
                <Choice />
            </Container>
      <div className="thank-you-box" id="thank-you-box">
        <div className="icon-mail">
          <img src={iconemail} alt="Mail Icon" className="env-icon" />
        </div>
        <h1>THANK YOU!</h1>
        <p>
          You have successfully submitted your quotation. A confirmation email has been sent to
          {' '}
          <span className="email-capture">{email}</span>
          {' '}
          with more information.
          {' '}
        </p>
        <p>Thanks again for visiting Top Animated Videos today. </p>
        <button className="alright-btn" onClick={close}>Alright!</button>
      </div>
        </div>
     );
}
 
export default RequestQuotation;