import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import screenshort4 from '../assets/images/Screenshot4.png'
import CardDetails from './CardDetails';

const Payment = () => {
    const [isCard, setIsCard] = useState(false)

    return ( 
        <div className='payment-container'>
            <Container>
                <div className='payment-header-section'>
                    <h4 className='payment-title'>Payment</h4>
                    <button onClick={() => setIsCard(true)}>Toggle</button>
                    <div className='paytext-section'>
                        <div>
                            <h4 className='payment-2d-title'>2D ANIMATED VIDEO</h4>
                            <p className='missout-el'> <span style={{fontWeight:'700'}}>Don't miss out on this exclusive opportunity!</span> We are offering a limited time discount of 40% off on all animated video services. MAKE YOUR ORDER NOW, our team of experts will bring your story to life and give your audience a dynamic and engaging viewing experience.</p>
                        </div>
                        <img src={screenshort4} className='payment-image' alt='payment' />
                    </div>
                </div>
                <div className='payment-main-section'>
                    <div>
                        <h5 className='one-select'>1. Select Package</h5>
                        <div className='price-length-value'>
                            <div>
                                <ul>
                                    <li><input type="radio" name="select-packages" id="2D-30-Seconds" /><label for="2D-30-Seconds">2D-Animated Video 30 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-60-Seconds" /><label for="2D-60-Seconds">2D-Animated Video 60 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-90-Seconds" /><label for="2D-90-Seconds">2D-Animated Video 90 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-120-Seconds" /><label for="2D-120-Seconds">2D-Animated Video 120 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-150-Seconds" /><label for="2D-150-Seconds">2D-Animated Video 150 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-180-Seconds" /><label for="2D-180-Seconds">2D-Animated Video 180 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-210-Seconds" /><label for="2D-210-Seconds">2D-Animated Video 210 Seconds</label></li>
                                    <li><input type="radio" name="select-packages" id="2D-240-Seconds" /><label for="2D-240-Seconds">2D-Animated Video 240 Seconds</label></li>
                                </ul>
                            </div>
                            <div className='paymet-pricing'>
                                <ul>
                                    <li><label for="2D-30-Seconds">$808.00</label></li>
                                    <li><label for="2D-60-Seconds">$808.00</label></li>
                                    <li><label for="2D-90-Seconds">$2,204.00</label></li>
                                    <li><label for="2D-120-Seconds">$3,600.00</label></li>
                                    <li><label for="2D-150-Seconds">$3,490.00</label></li>
                                    <li><label for="2D-180-Seconds">$3,600.00</label></li>
                                    <li><label for="2D-210-Seconds">$4,296.00</label></li>
                                    <li><label for="2D-240-Seconds">$5,694.00</label></li>
                                </ul>
                            </div>
                        </div>

                        <div style={{background:'#FAFAFA', maxWidth:'622px', padding: '10px'}}>
                            <h5 className='one-select'>2. Payment Method</h5>
                            <div className='input-method'>
                                <input type="radio" name="input-method" id="payPal" onClick={() => setIsCard(false)} /><label for="payPal"  >PayPal</label> <br />
                                <input type="radio" name="input-method" id="google-pay" onClick={() => setIsCard(false)} /><label for="google-pay" >Google Pay</label> <br />
                                <input type="radio" name="input-method" id="credit-debit-card" onClick={() => setIsCard(true)}  /><label for="credit-debit-card">Credit/Debit card</label>
                            </div>

                            {isCard && <CardDetails />}

                            <div className='confirm-and-pay'>Confirm and Pay</div>
                            <div className='secured-payment'>SSL Secured Payment</div>

                            <div className='disclaimer'>By checking out you agree with our <a href='/termsofuse'>Terms Of Service</a> We will process your personal data for the fulfillment of your order and other purposes as per our <a href='/privacypolicy'>Privacy Policy</a>. You can cancel recurring payments at any time.</div>
                        </div>
                    </div>
                    
                    <div className='package-get two-d-animation payment-package'>
                        <p className='will-get'>Here's What You Will Get</p>
                        <ul>
                            <li>High Impact Script</li>
                            <li>Compelling Storyboard</li>
                            <li>Dynamic animation</li>
                            <li>Impressive Voice-Over</li>
                            <li>Illustrated Background</li>
                            <li>Enjoyable Music</li>
                            <li>Sound After Effects</li>
                            <li>Standard Branding Elements</li>
                            <li>Standard Interactivity Effects</li>
                            <li>Number of Revisions (x3)</li>
                            <li>Fullfiment Time(4 weeks)</li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
     );
}
 
export default Payment;