import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import manWatching from '../assets/images/man-watching-online-video.png'

const Benefits = () => {
    return ( 
        <div>
            <div className='benefits-section'>
            <Container>
                <div className='benefits-header'>
                    <h1 className='section-header'>Benefits of Animated Videos</h1>
                    <p>The animated videos are uniquely rich in content, more engaging, and much easier to manage. They offer an excellent visual medium that compels, persuades, and convinces the target audience to take action. Here are the benefits of using animated videos to drive sales from the target customers.</p>
                </div>
                <div className='benefits-content'>
                    <div className='watching-image'>
                        <img src={manWatching} alt='man watching online video' className='man-watching' />
                    </div>
                    <div className='collapse-content'>
                        <Accordion defaultActiveKey='0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Brings any concept to life</Accordion.Header>
                                <Accordion.Body>
                                    Any concept can be brought out through animated videos enabling you not to limit your imagination.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Great medium to give context</Accordion.Header>
                                <Accordion.Body>
                                    Animated videos are a great medium to give context to even the most far-fetched ideas.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Strike a broader range of emotional tones</Accordion.Header>
                                <Accordion.Body>Animated videos are a great medium to give context to even the most far-fetched ideas.</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Capturing and Presenting Abstract ideas</Accordion.Header>
                                <Accordion.Body>With simplicity animated videos enable your business or organisation to capture and present your ideas magically.</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                
            </Container>
          </div>
        </div>
     )
}
 
export default Benefits