import React from 'react';

const CardDetails = () => {
    return ( 
        <div className='payment-card-details'>
            <div>
                <label for="card-name">Card Name</label><br/>
                <input type="text" id="card-name" placeholder='eg. John Doe' required/>

                <label for="card-code">Card Code</label><br/>
                <input type="text" id="card-code" placeholder='000' required/>
            </div>
            
            <div>
                <label for="card-number">Card Number</label><br/>
                <input type="text" id="card-number" placeholder=' 0000-0000-0000' required/>

                <label for="expiry-date">Expiry Date</label><br/>
                <input type="text" id="expiry-date" placeholder='MM/YY' required/>
            </div>
        </div>
     );
}
 
export default CardDetails;