import React from 'react';
import { Container } from 'react-bootstrap';
import videoAmico from '../assets/images/videoAmico.png'
import { useNavigate } from "react-router-dom";

const VideoMic = () => {
    const navigate = useNavigate()

    const handleGetStated = () => {
        //navigate('/requestquotation')

        window.open ('https://calendly.com/taav/free?hide_gdpr_banner=1', '_blank');
    }
    return ( 
        <div className='video-mic' id='top'>
           <Container>
                <div className='vid-section'>
                    <div className='video-text'>
                        <p className='text-title'>Make Your Point Crystal Clear through Animated Video Explainers</p>
                        <p className='text-content'>"Stand Out from the Crowd with Unique Engaging and Compelling Animated Videos.</p>
                        <div>
                            <span className='get-started' onClick={handleGetStated}>Free Consultation</span>
                        </div>
                    </div>
                    <img src={videoAmico} alt='video Amico' className='amico' />
                </div>
           </Container>
        </div>
     );
}
 
export default VideoMic;