import React from 'react';
import VideoMic from './VideoMic';
import Benefits from './Benefits';
import Choice from './Choice';
import Packages from './Packages';
import Process from './Process';
import Portfolio from './Portfolio';
import AboutUs from './AboutUs';
import CommonQuestions from './common-questions';
import GetInTouch from './GetInTouch';


const Home = () => {
    return ( 
        <div>
            <VideoMic />
            <Benefits />
            {/* <Choice /> */}
            <Packages />
            <Process />
            <Choice />
            <Portfolio />
            <AboutUs />
            <CommonQuestions />
            <GetInTouch />
            
        </div>
     )
}
 
export default Home;