import React from 'react';
import {Container} from 'react-bootstrap';
import footerlogo from '../assets/images/top-01.svg';
import {useForm} from 'react-hook-form';
import iconemail from '../assets/images/icon-email.png';

const Footer = () => {
  const {register, handleSubmit, formState: {errors}, reset} = useForm ();

  const onSubmit = data => {
    console.log (data);
    document.getElementById ('thank-you-box').style.display = 'block';
    reset (); // <-- reset the form after submitting
  };

  const close = () => {
    document.getElementById ('thank-you-box').style.display = 'none';
    reset (); // <-- reset the form after submitting
  };

  return (
    <footer>
      <Container>
        <div className="footer-content">
          <div className="logo-desc">
            <img
              src={footerlogo}
              alt="Top Animated Videos"
              className="footer-logo"
            />
            <div className="footer-desc">
              We have crafted  a pricing package for all the budgets of our prospectus clients, as shown below. Video length is the core parameter in pricing. Our clients make savings when they order a video with a longer video.This pricing is based on the normal working pace from our end. When a client needs the video faster (rush order), a small fee is applied to fast-track the delivery.
            </div>
          </div>
          <div className="useful-links">
            <ul>
              <li style={{fontSize: '13px', fontWeight: '400'}}>
                USEFUL LINKS
              </li>
              <li><a href="/termsofuse">Terms Of Use</a></li>
              <li><a href="/privacypolicy">Privacy Policy</a></li>
              <li><a href="/contactus">Contact Us</a></li>
              <li>
                <a
                  href="https://calendly.com/taav/free?hide_gdpr_banner=1"
                  target="_blank"
                >
                  {' '}Free Consultation
                </a>
              </li>
            </ul>
          </div>
          <div className="useful-links">
            <ul>
              <li style={{fontSize: '13px', fontWeight: '400'}}>NEWS LETTER</li>
              <li>Submit your email to get our weekly updates and news</li>
              <form className="footer-form" onSubmit={handleSubmit (onSubmit)}>
                <li>
                  <input
                    type="text"
                    placeholder="Name"
                    className="newsletter-input"
                    {...register ('naame', {required: true, maxLength: 25})}
                  />
                </li>
                <li>
                  {errors.email &&
                    <p className="text-error">Please check the Last Name</p>}
                </li>

                <li>
                  <input
                    type="email"
                    placeholder="Email"
                    className="newsletter-input"
                    {...register ('email', {
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                </li>
                <li>
                  {errors.email &&
                    <p className="text-error">Please check the Last Name</p>}
                </li>

                <li>
                  <button type="submit" className="submit-btn">Submit</button>
                </li>
              </form>
            </ul>
          </div>
        </div>
        <div>
          <p className="copyright">
            {new Date ().getFullYear ()}
            {' '}
            Top Animated Videos, All Rights Reserved
          </p>
        </div>
      </Container>
      <div className="thank-you-box" id="thank-you-box">
        <div className="icon-mail">
          <img src={iconemail} alt="Mail Icon" className="env-icon" />
        </div>
        <h1>THANK YOU!</h1>
        <p>Thanks again for subscribing to our newsletter. </p>
        <button className="alright-btn" onClick={close}>Alright!</button>
      </div>
    </footer>
  );
};

export default Footer;
