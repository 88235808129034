import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CommonQuestions = () => {
    const navigate = useNavigate()

    const handleGetStated = () => {
        //navigate('/requestquotation')

        window.open ('https://calendly.com/taav/free?hide_gdpr_banner=1', '_blank');
    }

    return ( 
        <div className='process-container'>
            <Container>
                <div className='about-header common-questions-header'>
                    <h1 className='section-header'>Common Questions</h1>
                    {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p> */}
                    <div className='why-get-section'>
                        <span className='get-started why-get-btn' onClick={handleGetStated}>Free Consultation</span>
                    </div>
                </div>
                <div className='common-questions'>
                <Accordion defaultActiveKey="0" className='accordion-div'>
                    <div>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What kind of animated videos do you offer?</Accordion.Header>
                            <Accordion.Body>
                                We offer a variety of animated video styles, including 2D animation, 3D animation, motion graphics, explainer videos, and more.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Can you create an animated video for my business?</Accordion.Header>
                            <Accordion.Body>
                                Absolutely! We specialize in creating custom animated videos for SMEs of all industries
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What is the process for creating an animated video?</Accordion.Header>
                            <Accordion.Body>
                                Our process typically consists of a consultation to understand your needs, followed by scriptwriting, storyboarding, animation, and final review.
                            </Accordion.Body>
                        </Accordion.Item>
                    
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How long does it take to create an animated video?</Accordion.Header>
                            <Accordion.Body>
                                The timeline for creating an animated video can vary, but on average it takes 4-6 weeks from start to finish.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Can you provide voiceover for my animated video?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we can provide voiceover services in multiple languages and with a variety of accent options.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Do you provide revisions for the animated video?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we offer a certain number of revisions in based on the packages to ensure that you are completely satisfied with the final product.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Can you help me with the script for my animated video?</Accordion.Header>
                            <Accordion.Body>
                                Yes, our team of scriptwriters can help you create a compelling script that effectively communicates your message.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>What format will I receive the final animated video in?</Accordion.Header>
                            <Accordion.Body>You will receive the final animated video in the format of your choice, such as MP4, or MOV, so you can easily use it on your website, social media, or any other platform.</Accordion.Body>
                        </Accordion.Item>

                    </div>
                    <div>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Do you offer a satisfaction guarantee for your animated videos?</Accordion.Header>
                            <Accordion.Body>Yes, we stand behind the quality of our work and offer a satisfaction guarantee for all of our animated videos.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>How do I get started with creating an animated video for my business?</Accordion.Header>
                            <Accordion.Body>Simply select a package to submit your request and we'll take it from there. We're here to make the process of creating an animated video easy and stress-free for you.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Can you create an animated video in a specific style or with specific branding elements?</Accordion.Header>
                            <Accordion.Body>Yes, we can create an animated video in any style you desire and incorporate your company's branding elements, such as logos, color schemes, and font styles</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>Can I see a portfolio of your past work?</Accordion.Header>
                            <Accordion.Body>Yes, you can view our portfolio on our website to see examples of our previous work and the quality of our animation.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Can you provide music and sound effects for my animated video?</Accordion.Header>
                            <Accordion.Body>Yes, we can provide a variety of music and sound effects options to enhance the impact of your animated video.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Do you have experience creating animated videos for my industry?</Accordion.Header>
                            <Accordion.Body>Yes, we have experience creating animated videos for a wide range of industries, including technology, healthcare, finance, insurance, education, and more.</Accordion.Body>
                        </Accordion.Item>
                      
                    </div>
                </Accordion>
                </div>
            </Container>
        </div>
     );
}
 
export default CommonQuestions;