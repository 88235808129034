import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate()

    const handleGetStated = () => {
       // navigate('/requestquotation')

       window.open ('https://calendly.com/taav/free?hide_gdpr_banner=1', '_blank');
    }

    return ( 
        <div className='process-container about-us' id='about'>
            <Container className='about-container'>
                <div className='about-header'>
                    <h1 className='section-header aaa'>About Us</h1>
                    <p style={{ color:'#372E7C', fontWeight:'700' }}>High Quality Animated Videos At the Best Affordable Prices </p>
                    
                </div>
                <div className='about-content'>
                    <p>Top Animated Videos is an international business that delivers high-converting animated videos to drive sales results. Our company has a niche in the finance and insurance sector for empowering small, medium, and large brands to connect with potential clients using animated videos.</p>
                    <p>We have tremendous excellent knowledge of the finance and insurance sector to create the best-in-class animated videos for our clients in these sectors that gives them an unfair advantage over their competitors. Our international team of experts and content creators has decades of experience with animated video production that guarantees the highest quality standards for our clients.</p>
                    <p>Our animated videos are uniquely developed with rich content, more engaging, and fun to capture the targeted audience's attention completely. They provide excellent visual media that compels, persuades, and convinces the target audience to take action.</p>
                </div>
                <div className='why-get-section'>
                    <span className='get-started why-get-btn' onClick={handleGetStated}>Free Consultation</span>
                </div>
            </Container>
        </div>
     );
}
 
export default AboutUs;