import React from 'react';
import { Container } from 'react-bootstrap';


const Portfolio = () => {
    return ( 
        <div className='process-container' id='portfolio'>
            <Container>
                <div className='packages-header portfolio-header'>
                    <h1 className='section-header'>Our Portfolio</h1>
                    <p style={{textAlign:'center'}}>Before browsing further through our site, it would be vital to see what we can deliver for your business. Here is a collection of a few animated videos we have produced in the recent past to help validate our capabilities.</p>
                </div>
                <div style={{marginTop:'100px'}}>
                    <div className='iframe-section'>
                        <iframe src="https://player.vimeo.com/video/725695267?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                        <iframe src="https://player.vimeo.com/video/725695168?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                        <iframe src="https://player.vimeo.com/video/725695285?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                    </div>
                    <div className='iframe-section'>
                        <iframe src="https://player.vimeo.com/video/725695118?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                        <iframe src="https://player.vimeo.com/video/725695320?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                        <iframe src="https://player.vimeo.com/video/725695403?h=806852a805" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="myFrame" allowfullscreen></iframe>
                    </div>
                </div>
            </Container>
        </div>
     );
}
 
export default Portfolio;