import React, { useState } from 'react';
import logo from '../assets/images/22.png'

const MobileMenu = () => {
    const [show, setShow] = useState(false)
    const closeMenu = (e) => {
        setShow(!show)
        e.classList.toggle("bi bi-list")
    }
    return ( 
        <div className='hamburger'>
            <div className='container'>
                <header className='ham-header'>
                    <a href='/#top' className='mobile-nav-link'><img src={logo} alt='Top Animated Videos' className='mobile-logo' /></a>
                    <div className='close'><i className={show ? "bi bi-x-lg" : "bi bi-list"} onClick={closeMenu}></i></div>
                </header>
                <div>
                    {
                        show ?
                        <ul className='nav-menu'>
                        <li><a href='/#top' className='mobile-nav-link'>Home</a></li>
                        <li><a href='/#packages' className='mobile-nav-link'>Packages</a></li>
                        <li><a href='/#process' className='mobile-nav-link'>Process</a></li>
                        <li><a href='/#portfolio' className='mobile-nav-link'>Portfolio</a></li>
                        <li><a href='/#about' className='mobile-nav-link'>About Us</a></li>
                    </ul>
                    : <></>
                    }
                </div>
            </div>
        </div>
     )
}
 
export default MobileMenu;