import React from 'react';
import { Container } from 'react-bootstrap';
import screenshort5 from '../assets/images/Screenshot5.png'
import screenshort4 from '../assets/images/Screenshot4.png'
import screenshort3 from '../assets/images/Screenshot3.png'
import { useNavigate } from 'react-router';

const Packages = () => {

    const navigate  = useNavigate()
    
    const handleQuote = () => {
        window.scrollTo(0,0)
        
        window.open ('https://calendly.com/taav/free?hide_gdpr_banner=1', '_blank');
    }

    return ( 
        <div className='packages-container' id='packages'>
            <Container>
                <div className='packages-header'>
                    <h1 className='section-header'>Our Packages</h1>
                    <p>We have crafted  a pricing package for all the budgets of our prospectus clients, as shown below. Video length is the core parameter in pricing. Our clients make savings when they order a video with a longer video duration.This pricing is based on the normal working pace from our end. When a client needs the video faster (rush order), a small fee is applied to fast-track the delivery.</p>
                </div>
                <div className='choose-package'>
                    <div className='single-package whiteboard'>
                        <div className='single-package-header'>
                            <img src={screenshort5} alt='screenshot5' className='screenshot' />
                            <h1 className='single-title'>whiteboard Animated Videos</h1>
                        </div>
                        <ul className='package-ul'>
                            <li>High Impact Script</li>
                            <li>Compelling Storyboard</li>
                            <li>Dynamic animation</li>
                            <li>Impressive Voice-Over</li>
                            <li>Illustrated Background</li>
                            <li>Enjoyable Music</li>
                            <li>Sound After Effects</li>
                            <li>Standard Branding Elements</li>
                            <li>Standard Interactivity Effects</li>
                            <li>Number of Revisions (x3)</li>
                            <li>Fullfiment Time(4 weeks)</li>
                        </ul>
                        <form className='choose-form'>
                            <div className='order-now-section'>
                                <button className='order-now-btn' onClick={handleQuote}>USD $620 - 30 Seconds</button>
                            </div>
                        </form>
                    </div>
                    <div className='single-package two-d-animation'>
                        <div className='single-package-header'>
                            <img src={screenshort4} alt='screenshot5' className='screenshot' />
                            <h1 className='single-title'>2D Animated Videos</h1>
                        </div>
                        <ul className='package-ul'>
                            <li>High Impact Script</li>
                            <li>Compelling Storyboard</li>
                            <li>Dynamic animation</li>
                            <li>Impressive Voice-Over</li>
                            <li>Illustrated Background</li>
                            <li>Enjoyable Music</li>
                            <li>Sound After Effects</li>
                            <li>Standard Branding Elements</li>
                            <li>Standard Interactivity Effects</li>
                            <li>Number of Revisions (x3)</li>
                            <li>Fullfiment Time(4 weeks)</li>
                        </ul>
                        <form className='choose-form'>
                            <div className='order-now-section'>
                                <button className='order-now-btn' onClick={handleQuote}>USD $1100 - 30 Seconds</button>
                            </div>
                        </form>
                    </div>
                    <div className='single-package lite-two-d-animation'>
                        <div className='single-package-header'>
                            <img src={screenshort3} alt='screenshot5' className='screenshot' />
                            <h1 className='single-title'>Premium Animated Videos</h1>
                        </div>
                        <ul>
                            <li>High Impact Script</li>
                            <li>Compelling Storyboard</li>
                            <li>Dynamic animation</li>
                            <li>Impressive Voice-Over</li>
                            <li>Illustrated Background</li>
                            <li>Enjoyable Music</li>
                            <li>Sound After Effects</li>
                            <li>Standard Branding Elements</li>
                            <li>Standard Interactivity Effects</li>
                            <li>Highly Optimized</li>
                            <li>Unique Customization</li>
                            <li>ELITE Premium Services</li>
                            <li>Number of Revisions (x3)</li>
                            <li>Fullfiment Time(4 weeks)</li>
                        </ul>
                        <form className='choose-form'>
                            <div className='order-now-section'>
                                <button className='order-now-btn' onClick={handleQuote}>USD $1850 - 30 Seconds</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </div>
     );
}
 
export default Packages;