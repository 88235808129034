import React from 'react';
import { Container } from 'react-bootstrap';
import solarSystem1 from '../assets/images/vector.png'
import workingHours from '../assets/images/conversation-bubble2.png'
import contentManagement from '../assets/images/record-voice-over3.png'
import group from '../assets/images/animation3.png'

const Choice = () => {
    return ( 
        <div className='process-container'>
            <Container>
                <div className='benefits-header why-choose-header'>
                    <h1 className='section-header'>Why Choose Us</h1>
                    <p>Our team boasts unmatched experience and advanced skills in crafting top-tier animated videos for global brands. Highly Specializing in creating impactful content with transparent pricing</p>
                    {/* <div className='why-get-section'>
                        <span className='get-started why-get-btn'>Free Consultation</span>
                    </div> */}
                </div>
                <div className='choice-section'>
                    <div className='choice-card'>
                        <div className='choice-content'>
                            <img src={solarSystem1} alt='solar system 1' className='choice-vector' />
                            <div>
                                <p className='choice-title'>Tremendous experience </p>
                                <p className='choice-text'>Our team of international experts has decades of experience with advanced skills in delivering high-quality animated videos for several brands worldwide.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choice-card'>
                        <div className='choice-content'>
                            <img src={workingHours} alt='solar system 1' className='choice-vector' />
                            <div>
                                <p className='choice-title'>Highly Specialisation</p>
                                <p className='choice-text'>We have a niche in SMEs with excellent knowledge to create technically amazing videos for immediate and lasting impactful results.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='choice-section'>
                    <div className='choice-card'>
                        <div className='choice-content'>
                            <img src={contentManagement} alt='solar system 1' className='choice-vector' />
                            <div>
                                <p className='choice-title'>Best Quality at Best Price</p>
                                <p className='choice-text'>Our clients need the highest possible quality of customized videos at the most affordable price on the market. Our focus is on ensuring our clients can experience true value for their money.</p>
                            </div>
                        </div>
                    </div>
                    <div className='choice-card'>
                        <div className='choice-content'>
                            <img src={group} alt='solar system 1' className='choice-vector' />
                            <div>
                                <p className='choice-title'>Simple & Transparent Pricing</p>
                                <p className='choice-text'>Each package is priced based on the length of the video and associated features. What you see is what you pay, nothing more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
     );
}
 
export default Choice;